<template>
  <div class="gzr_article">
    <div class="list_content">
      <ul class="article_list_box">
        <li v-for="(item, i) in articleList" :key="i">
          <h4 @click="goGzrPdfPage(item.qiniuUrl)" class="title">{{1+i}}. {{item.titleZh}}</h4>
          <p>项目名称: {{item.fundProject}}</p>
          <p>学科领域: {{item.fieldCodeName}}</p>
          <p>作者: {{item.authorsStr}}</p>
          <div class="options_box">
            <span>资助类型: {{item.supportTypeName}}</span>
            <span>研究机构: {{item.organization}}</span>
          </div>
          <div class="options_box">
            <span>期刊: {{item.journalName}}</span>
            <span>发表时间: {{item.publishDate}}</span>
          </div>
        </li>
      </ul>
      <div class="loading morebtn_box no_select">
        <span @click="onMoreBtn" v-if="isMoreBtn" class=" hand">加载更多>></span>
        <span v-else>暂无更多</span>
      </div>
    </div>

  </div>
</template>
<script>
import { getDocumentListByXmpzh } from '@/api/guoZiRan'
import { blankPagePar } from '@/utils/index'
export default {
  data () {
    return {
      parameter: {
        pageNum: 1,
        pageSize: 10,
        xmpzh: ''
      },
      articleList: [],
      isMoreBtn: false // 加载更多按钮状态

    }
  },
  methods: {
    // 查看国自然pdf
    goGzrPdfPage (url) {
      if (!url) return this.$message.warning('暂无PDF')
      blankPagePar('gzrPdfPage', { url })
    },
    // 加载更多
    onMoreBtn () {
      this.parameter.pageNum++
      this.getDocumentListByXmpzh()
    },
    // 获取项目相关文献列表
    async getDocumentListByXmpzh () {
      const res = await getDocumentListByXmpzh(this.parameter)
      console.log(res)
      if (res.code !== 0) return this.$message.error('获取相关文献失败')
      this.isMoreBtn = res.data && res.data.length >= this.parameter.pageSize
      this.articleList = [...this.articleList, ...res.data]
    }
  },
  created () {
    this.parameter.xmpzh = this.$route.query.projectId
    this.getDocumentListByXmpzh()
  }
}
</script>
<style lang="less" scoped>
.gzr_article {
  width: 100%;
  min-height: calc(100vh - 252px);
  background-color: #f5f7fb;
  .list_content {
    width: 1210px;
    margin: 0 auto;
    background-color: #f5f7fb;
    .article_list_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 20px;
      li {
        width: 49%;
        padding: 20px;
        border-radius: 8px;
        background-color: #fff;
        margin-bottom: 20px;
        cursor: default;
        color: #666;
        font-size: 13px;
        transition: all 0.3s;
        &:hover {
          box-shadow: 6px 6px 7px 0 rgba(204, 204, 204, 0.5);
        }
        .title {
          font-size: 16px;
          font-weight: 600;
          width: 100%;
          height: 42px;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          cursor: pointer;
          color: #000;
          &:hover {
            text-decoration: underline;
          }
        }
        p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 6px;
        }
        .options_box {
          display: flex;
          justify-content: space-between;
          margin-bottom: 6px;
        }
      }
    }
    .morebtn_box {
      text-align: center;
      padding: 10px 0 20px;
      font-size: 16px;
      font-weight: 600;
      color: #4b639f;
      cursor: default;
    }
  }
}
</style>
