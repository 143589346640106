import request from '../utils/request.js'
// 国自然相关接口

// 获取筛选条件列表
export function getSelectList (data) {
  return request({
    url: '/medtools/gzr/getGzrjjFieldDict',
    method: 'POST',
    data,
    headers: {
      hideLoading: true
    }
  })
}

// 获取项目列表
export function getGzrSearchList (data) {
  return request({
    url: '/medtools/gzr/gzrjjSearch',
    method: 'POST',
    data
  })
}

// 获取国自然项目文献列表
export function getDocumentListByXmpzh (data) {
  return request({
    url: '/medtools/gzr/getDocumentListByXmpzh',
    method: 'POST',
    data
  })
}
